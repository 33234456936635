var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Mẫu điểm danh đến", name: "arriveTab" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableArrive,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.arriveDataList,
                        "cell-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "attendanceContent",
                          label: "Nội dung mẫu điểm danh",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Nguồn",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.idSchool == 0
                                  ? _c("span", [_vm._v("Hệ thống")])
                                  : _c("span", [_vm._v("Nhà trường")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.checkPermission([
                        "schoolConfig_attendanceSample_update",
                      ])
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "success",
                                                disabled:
                                                  scope.row.idSchool === 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateAttendanceSampleRow(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Sửa")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                                disabled:
                                                  scope.row.idSchool === 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAttendanceSampleOneMethod(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Xóa")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1860747622
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.checkPermission(["schoolConfig_attendanceSample_update"])
                    ? _c(
                        "div",
                        { staticStyle: { float: "right", margin: "15px 0" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "button-bottom",
                              attrs: { type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.createAttendanceSampleMethod()
                                },
                              },
                            },
                            [_vm._v("Thêm mẫu điểm danh")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Mẫu điểm danh về", name: "leaveTab" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableLeave,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.leaveDataList,
                        "cell-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "attendanceContent",
                          label: "Nội dung mẫu điểm danh",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Nguồn",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.idSchool == 0
                                  ? _c("span", [_vm._v("Hệ thống")])
                                  : _c("span", [_vm._v("Nhà trường")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.checkPermission([
                        "schoolConfig_attendanceSample_update",
                      ])
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "success",
                                                disabled:
                                                  scope.row.idSchool === 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateAttendanceSampleRow(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Sửa")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                                disabled:
                                                  scope.row.idSchool === 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAttendanceSampleOneMethod(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Xóa")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1860747622
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.checkPermission(["schoolConfig_attendanceSample_update"])
                    ? _c(
                        "div",
                        { staticStyle: { float: "right", margin: "15px 0" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "button-bottom",
                              attrs: { type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.createAttendanceSampleMethod()
                                },
                              },
                            },
                            [_vm._v("Thêm mẫu điểm danh")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AttendanceSampleUpdate", {
        ref: "AttendanceSampleUpdate",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
      _c("AttendanceSampleCreate", {
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }